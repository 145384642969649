import { getBaseUrl } from "./utils/baseUrlHelper";

export const localBaseUrl = "http://localhost:4001/"
export const testBaseUrl = "https://apis.digital.stage.gcisikar.com/";
export const liveBaseUrl = "https://apis.digital.stage.gcisikar.com/";
export const strApiBaseUrl = "http://localhost:1337/api";

export const websiteUrl = "https://web.digital.stage.gcisikar.com";

const isclient = typeof window !== "undefined";
if (isclient) {
  console.log("[loc]", window.location);
}
export const BaseURL = isclient
  ? getBaseUrl(window.location?.hostname)
  : liveBaseUrl;
