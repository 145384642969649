import {
  liveBaseUrl,
  localBaseUrl,
  testBaseUrl,
} from "../BaseUrl";

export function getBaseUrl(host) {
  console.log({ host });
  if (host === "localhost") {
    return localBaseUrl;
  } else if (host === "students.digital.stage.gcisikar.com") {
    return testBaseUrl;
  } else if (
    host === "students.gurukripa.ac.in" ||
    host === "digital.gurukripa.ac.in"
  ) {
    return liveBaseUrl;
  } else {
    return liveBaseUrl;
  }
}
